import { axiosInstance } from '../../axios'

export const faqServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('faqs', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`faqs/${id}`, requestModel)
  },
  findAll: (projectId: string, languageCode: string) => {
    return axiosInstance.get(`faqs/findAll/${projectId}/${languageCode}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`faqs/findOne/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`faqs/${id}`)
  },
  findAllDefault: () => {
    return axiosInstance.get(`faqs/findAllDefault/en`)
  },
}
