import { axiosInstance } from '../../axios'

export const projectServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('projects', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`projects/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('projects')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`projects/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`projects/${id}`)
  },
  activateProject: (id: string, isActive: number) => {
    return axiosInstance.get(`projects/${id}/${isActive}`)
  },
}
