import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { languageServices } from '../../services/common/languages/index'
interface LanguageData {
  code: string
  name: string
}

interface ArrayObjectSelectState {
  selectedLanguage: LanguageData | null
  setSelectedLanguage: (language: LanguageData | null) => void
  code?: string
}

const LanguageSelect: React.FC<ArrayObjectSelectState> = ({ selectedLanguage, setSelectedLanguage, code }) => {
  const [languages, setLanguages] = React.useState<LanguageData[]>([])

  useEffect(() => {
    const loadLanguages = async () => {
      const response = await languageServices.findAll()
      if (response) {
        const languageData = response.data as LanguageData[]
        setLanguages(
          languageData.map<LanguageData>((item) => {
            return {
              code: item.code,
              name: item.name,
            }
          }),
        )
        if (code) {
          const language = languageData.find((item) => item.code === code)
          if (language) setSelectedLanguage(language)
        }
      } else {
        toast('An error occurred while loading the Language data.')
      }
    }
    loadLanguages()
  }, [code, setSelectedLanguage])

  return (
    <Select
      value={selectedLanguage}
      onChange={(option: LanguageData | null) => setSelectedLanguage(option)}
      getOptionLabel={(option: LanguageData) => option.name}
      getOptionValue={(option: LanguageData) => option.name}
      options={languages}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder='LANGUAGE'
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default LanguageSelect
