import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import React from 'react'
interface DatePickerComponentProps {
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({ selectedDate, setSelectedDate }) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date: Date) => setSelectedDate(date)}
      dateFormat='dd/MM/yyyy'
      isClearable
      placeholderText='Select Date'
    />
  )
}

export default DatePickerComponent
