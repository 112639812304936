import { Box, Text } from '@chakra-ui/react'

export default function Congratulation() {
  return (
    <Box
      w='100%'
      h='100vh'
      bgImage={'https://i.ibb.co/tBgDbjS/cong-image.jpg'}
      bgPosition='center'
      bgRepeat='no-repeat'
      bgSize='cover'
      fontFamily={'cursive'}
    >
      <Box
        w='100%'
        h='100%'
        bgColor='rgba(0, 0, 0, 0.5)'
        display='flex'
        justifyContent='center'
        alignItems='flex=start'
        pt={20}
        children={<Text color={'white'} fontSize={'6xl'} children={'Congratulation'} />}
      />
    </Box>
  )
}
