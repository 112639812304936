import { TableContainer, Table, Tbody, Thead, Td, Th, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { MdOutlineManageAccounts } from 'react-icons/md'

// Services
import { employeeServices } from '../../services/employees/index'

import DefaultLayout from '../../layouts/DefaultLayout'

// Components
import ActionButton from '../../components/customFormElements/ActionButtons'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../components/shareds/LoadingComponent'
import NavCard from '../../components/cards/navCard/NavigateCard'

const EmployeeDetail = () => {
  const [manageEmployeeData, setManageEmployeeData] = useState<any[]>([])
  const navigate = useNavigate()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    employeeServices.findAll('', '', 1, 10).then((response) => {
      if (response) {
        setManageEmployeeData(response.data.items)
      } else {
        toast('An error occurred while loading the  data.')
      }
    })
  }, [])

  const updateEmployee = (id: string) => {
    navigate(`/manage-employee/update/${id}`)
  }

  const detailEmployee = (id: string) => {
    navigate(`/manage-employee/${id}`)
  }

  const deleteEmployee = (id: string) => {
    employeeServices.remove(id).finally(() => {})
  }

  if (!manageEmployeeData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={MdOutlineManageAccounts}
        pageName='Employee'
        secondButton='Add Employee'
        secondButtonLink='add'
        key={'Add_Employee'}
      />
      <GeneralCard title='Employee List'>
        <TableContainer maxH='30em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'First Name'} />
                <Th children={'Last Name'} />
                <Th children={'Email'} />
                <Th children={'Address'} />
                <Th children={'Type'} />
              </Tr>
            </Thead>
            {manageEmployeeData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td
                      onClick={() => detailEmployee(item.id)}
                      cursor={'pointer'}
                      color={'blue'}
                      children={item.firstName}
                    />
                    <Td children={item.lastName} />
                    <Td children={item.email} />
                    <Td children={item.address} />
                    <Td children={item.type} />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default EmployeeDetail
