import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { employeeTypeServices } from '../../services/employees/employeeTypes/index'

interface AssignedData {
  id: string
  firstName: string
  lastName: string
  type: string
}

interface ArrayObjectSelectState {
  selectedAssigned: AssignedData | null
  setSelectedAssigned: (assigned: AssignedData | null) => void
  assignedType: string
  selectAssigned?: string
}

const AssignedSelect: React.FC<ArrayObjectSelectState> = ({
  selectedAssigned,
  setSelectedAssigned,
  assignedType,
  selectAssigned,
}) => {
  const [assigned, setAssigned] = useState<AssignedData[]>([])

  useEffect(() => {
    employeeTypeServices.findEmployeesByType(assignedType).then((response) => {
      if (response) {
        const assignedTypeData = response.data as AssignedData[]
        setAssigned(
          assignedTypeData.map<AssignedData>((item) => {
            return {
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              type: item.type,
            }
          }),
        )
        if (selectAssigned) {
          const company = assignedTypeData.find((item) => item.id === selectAssigned)
          if (company) setSelectedAssigned(company)
        }
      } else {
        toast('An error occurred while loading the Assigned data.')
      }
    })
  }, [assignedType])

  return (
    <Select
      value={selectedAssigned}
      onChange={(option: AssignedData | null) => {
        setSelectedAssigned(option)
      }}
      getOptionLabel={(option: AssignedData) => option.firstName + ' ' + option.lastName}
      getOptionValue={(option: AssignedData) => option.firstName + ' ' + option.lastName}
      options={assigned}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={assignedType.toUpperCase()}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default AssignedSelect
