import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

import { MdWork } from 'react-icons/md'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { projectServices } from '../../../../services/common/projects/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import DatePickerComponent from '../../../../components/customFormElements/DatePicker'
import CustomCheckbox from '../../../../components/customFormElements/CustomCheckbox'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import CompanySelect from '../../../../components/selects/CompanySelect'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type ProjectFormData = {
  name: string
  title: string
  description: string
  number: string
  address: string
  person: string
  email: string
  logoUrl: string
  companyId: string
  isTermsheet: boolean
  isDefault: boolean
  establishedDate: string
}

const ProjectCreate = () => {
  const [selectedCompany, setSelectedCompany] = useState<any | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [termsheetChecked, setTermsheetChecked] = useState(false)
  const [defaultChecked, setDefaultChecked] = useState(false)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    number: Yup.string().required('Number is required'),
    address: Yup.string().required('Address is required'),
    person: Yup.string().required('Person is required'),
    email: Yup.string().email().required('E-mail is required'),
    logoUrl: Yup.string().required('Logo Url is required'),
    isTermsheet: Yup.boolean().required('Termsheet is required'),
    isDefault: Yup.boolean().required('Default is required'),
  })

  const handleTermsheetChange = (newCheckState: boolean) => {
    setTermsheetChecked(newCheckState)
  }

  const handleDefaultChange = (newCheckState: boolean) => {
    setDefaultChecked(newCheckState)
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ProjectFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: ProjectFormData) => {
    if (!data.establishedDate && !selectedDate?.toLocaleString()) {
      toast('Established Date is required')
      return
    }

    if (!data.companyId && !selectedCompany?.id) {
      toast('Company is required')
      return
    }

    data.establishedDate = data.establishedDate ?? selectedDate?.toISOString()
    data.companyId = data.companyId ?? selectedCompany?.id

    projectServices.create(data).finally(() => {
      navigate('/project')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Project' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Project'>
          <Grid templateRows='repeat(6, 1fr)' templateColumns='repeat(4, 1fr)' gap={6} margin={'20px'}>
            <CustomInputForm
              error={errors.name}
              children={<Input type='text' id='name' placeholder='Name' {...register('name')} />}
            />
            <CustomInputForm
              error={errors.title}
              children={<Input type='text' id='title' placeholder='Title' {...register('title')} />}
            />
            <CustomInputForm
              error={errors.description}
              children={<Input type='text' id='description' placeholder='Description' {...register('description')} />}
            />
            <CustomInputForm
              error={errors.number}
              children={<Input type='text' id='number' placeholder='Number' {...register('number')} />}
            />
            <CustomInputForm
              error={errors.address}
              children={<Input type='text' id='address' placeholder='Address' {...register('address')} />}
            />
            <CustomInputForm
              error={errors.person}
              children={<Input type='text' id='person' placeholder='Person' {...register('person')} />}
            />
            <CustomInputForm
              error={errors.email}
              children={<Input type='text' id='email' placeholder='Email' {...register('email')} />}
            />
            <CustomInputForm
              error={errors.logoUrl}
              children={<Input type='text' id='logoUrl' placeholder='Logo URL' {...register('logoUrl')} />}
            />
            <CustomInputForm
              children={<CompanySelect selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />}
            />
            <CustomInputForm
              children={<DatePickerComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />}
            />
            <CustomInputForm
              error={errors.isTermsheet}
              children={
                <CustomCheckbox
                  defaultChecked={termsheetChecked}
                  onCheckChange={handleTermsheetChange}
                  name={'Termsheet'}
                  register={register}
                  registerName='isTermsheet'
                />
              }
            />
            <CustomInputForm
              error={errors.isDefault}
              children={
                <CustomCheckbox
                  defaultChecked={defaultChecked}
                  onCheckChange={handleDefaultChange}
                  name={'Default'}
                  register={register}
                  registerName='isDefault'
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default ProjectCreate
