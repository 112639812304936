import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { companyServices } from '../../services/common/companies/index'
interface CompanyData {
  id: string
  name: string
}

interface ArrayObjectSelectState {
  selectedCompany: CompanyData | null
  setSelectedCompany: (company: CompanyData | null) => void
  id?: string
}

const CompanySelect: React.FC<ArrayObjectSelectState> = ({ selectedCompany, setSelectedCompany, id }) => {
  const [companies, setCompanies] = React.useState<CompanyData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const response = await companyServices.findAll('1', '100')
      if (response) {
        const companyData = response.data.items as CompanyData[]
        setCompanies(
          companyData.map<CompanyData>((item) => {
            return {
              id: item.id,
              name: item.name,
            }
          }),
        )
        if (id) {
          const company = companyData.find((item) => item.id === id)
          if (company) setSelectedCompany(company)
        }
      } else {
        toast('An error occurred while loading the Company data.')
      }
    }
    loadCountries()
  }, [id, setSelectedCompany])

  return (
    <Select
      value={selectedCompany}
      onChange={(option: CompanyData | null) => {
        setSelectedCompany(option)
      }}
      getOptionLabel={(option: CompanyData) => option.name}
      getOptionValue={(option: CompanyData) => option.name}
      options={companies}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'Company'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default CompanySelect
