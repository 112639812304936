import { Box, Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

// Services
import { userSocialServices } from '../../../../services/users/userSocial/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import SocialMediaSelect from '../../../../components/selects/SocialMediaSelect'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'

type UserSocialFormData = {
  userId: string
  socialMediaId: string
  socialMediaName: string
  profileLink: string
  socialAccountName: string
  followerNumber: number
}

const UserSocialUpdate = (props: { id: string; closeModal: () => void }) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<any | null>(null)
  const [userSocial, setUserSocialData] = useState<any | null>(null)
  const userId = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    profileLink: Yup.string().required('Profile Link is required'),
    followerNumber: Yup.number().required('Follower Number is required'),
  })

  useEffect(() => {
    userSocialServices.findOne(props.id).then((response) => {
      if (response) {
        setUserSocialData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserSocialData({
      ...userSocial,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserSocialFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: UserSocialFormData) => {
    if (!selectedSocialMedia) {
      toast('Social Media is required')
      return
    }

    data.socialMediaId = selectedSocialMedia.id
    data.socialMediaName = selectedSocialMedia.name.toUpperCase()
    data.userId = userId

    userSocialServices.update(props.id, data).finally(() => {
      props.closeModal()
    })
  }

  if (!userSocial) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Social Media'
            children={
              <SocialMediaSelect
                selectedSocialMedia={selectedSocialMedia}
                setSelectedSocialMedia={setSelectedSocialMedia}
                selectSocialMedia={userSocial.socialMediaName}
              />
            }
          />
          <CustomInputForm
            error={errors.profileLink}
            title='Profile Link'
            children={
              <Input
                type='text'
                id='profileLink'
                {...register('profileLink')}
                value={userSocial.profileLink}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.socialAccountName}
            title='Account Name'
            children={
              <Input
                type='text'
                id='socialAccountName'
                {...register('socialAccountName')}
                value={userSocial.socialAccountName}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.followerNumber}
            title='Follower Number'
            children={
              <Input
                type='text'
                id='followerNumber'
                {...register('followerNumber')}
                value={userSocial.followerNumber}
                onChange={handleInputChange}
              />
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </Box>
  )
}
export default UserSocialUpdate
