import {
  Box,
  Button,
  Flex,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Services
import { contractSignServices } from '../../../../services/users/userContract/index'

// Components
import ContractLevelSelect from '../../../../components/selects/ContractLevelSelect'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import PostingDaySelect from '../../../../components/selects/PostingDaySelect'
const steps = [
  { title: 'NDA', description: 'NDA' },
  { title: 'CONTRACT', description: 'Contract' },
]

const UserContractSign = () => {
  const [userContractData, setUserContractData] = useState<any | null>(null)
  const [selectedContractLevel, setSelectedContractLevel] = useState<any | null>(null)
  const [selectedPostingDay, setSelectedPostingDay] = useState<any | null>(null)
  const [contractLevelData, setContractLevelData] = useState<any | null>(null)

  const [activeStep, setActiveStep] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState({ contractLevel: false, contractTemplate: false, contractSign: false })
  const navigate = useNavigate()
  const token = location.pathname.split('/contract-sign/')[1]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setIsVisible(true)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setIsVisible(false)
  }

  const signContract = async () => {
    setLoading((prev) => ({ ...prev, contraccontractSigntLevel: true }))

    const requestModel = {
      contractLevelId: selectedContractLevel.id,
      postingDay: selectedPostingDay.value,
    }

    await contractSignServices.sign(token, requestModel).then((response) => {
      if (response) {
        navigate('/congratulation')
      }
    })
    setLoading((prev) => ({ ...prev, contraccontractSigntLevel: false }))
  }

  useEffect(() => {
    setLoading((prev) => ({ ...prev, contractTemplate: true }))

    const contractPromise = isVisible
      ? contractSignServices.fillOutTemplateContract(token)
      : contractSignServices.fillOutTemplateNda(token)

    contractPromise.then((response) => {
      setUserContractData(response.data)
      setLoading((prev) => ({ ...prev, contractTemplate: false }))
    })
  }, [contractLevelData, isVisible])

  useEffect(() => {
    setLoading((prev) => ({ ...prev, contractLevel: true }))

    contractSignServices.findContractLevel(token).then((response) => {
      setContractLevelData(response.data)
      setLoading((prev) => ({ ...prev, contractLevel: false }))
    })
  }, [])

  if (loading.contractLevel || loading.contractTemplate || loading.contractSign) return <LoadingComponent />

  return (
    <>
      <Stepper index={activeStep} mb={10}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator
              children={<StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />}
            />

            <Box flexShrink='0'>
              <StepTitle children={step.title} />
              <StepDescription children={step.description} />
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <>
        {isVisible && (
          <Flex direction={['column', 'column', 'row']} justify={'space-between'}>
            <Box
              w={['100%', '100%', '49%']}
              mb={5}
              children={
                <ContractLevelSelect
                  selectContractLevel={''}
                  setSelectedContractLevel={setSelectedContractLevel}
                  token={token}
                  selectedContractLevel={selectedContractLevel}
                />
              }
            />
            <Box
              w={['100%', '100%', '49%']}
              mb={5}
              children={
                <PostingDaySelect
                  selectPostingDay=''
                  selectedPostingDay={selectedPostingDay}
                  setSelectedPostingDay={setSelectedPostingDay}
                />
              }
            />
          </Flex>
        )}
      </>

      <Box width='100%' height='100%' overflow='auto' dangerouslySetInnerHTML={{ __html: userContractData }} />

      <Flex justify={'space-between'} mt={10}>
        <Button colorScheme='teal' onClick={handleBack} disabled={activeStep === 0} children='Back' />
        <>
          {isVisible ? (
            <Button
              colorScheme='yellow'
              size={'md'}
              w={200}
              onClick={signContract}
              disabled={activeStep === 1}
              children='Sign'
            />
          ) : (
            <Button colorScheme='blue' onClick={handleNext} disabled={activeStep === 1} children='Next' />
          )}
        </>
      </Flex>
    </>
  )
}

export default UserContractSign
