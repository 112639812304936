import { axiosInstance } from '../../axios'

export const contractLevelServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('contract-levels', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`contract-levels/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('contract-levels')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`contract-levels/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`contract-levels/${id}`)
  },
  activateProject: (id: string, isActive: number) => {
    return axiosInstance.get(`contract-levels/${id}/${isActive}`)
  },
}
