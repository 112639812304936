import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { MdQuestionAnswer } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { faqServices } from '../../../../services/common/faqs/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import ProjectSelect from '../../../../components/selects/ProjectSelect'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type FaqFormData = {
  title: string
  summary: string
  description: string
  orderNumber: number
  projectId: string
  languageCode: string
}

const FaqCreate = () => {
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    summary: Yup.string().required('Summary is required'),
    description: Yup.string().required('Description is required'),
    orderNumber: Yup.number().required('Orner Number is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FaqFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: FaqFormData) => {
    if (!data.projectId && !selectedProject?.id) {
      toast('Project is required')
      return
    }

    if (!data.languageCode && !selectedLanguage?.code) {
      toast('Language is required')
      return
    }

    data.projectId = data.projectId ?? selectedProject?.id
    data.languageCode = data.languageCode ?? selectedLanguage?.code

    faqServices.create(data).finally(() => {
      navigate('/faq')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Faq' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Faq'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.title}
              children={<Input type='text' id='title' placeholder='Title' {...register('title')} />}
              title='Title'
            />
            <CustomInputForm
              error={errors.summary}
              children={<Input type='text' id='summary' placeholder='Summary' {...register('summary')} />}
              title='Summary'
            />
            <CustomInputForm
              error={errors.description}
              children={<Input type='text' id='description' placeholder='Description' {...register('description')} />}
              title='Description'
            />
            <CustomInputForm
              error={errors.orderNumber}
              children={<Input type='text' id='orderNumber' placeholder='Order Number' {...register('orderNumber')} />}
              title='Order Number'
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
              }
            />
            <CustomInputForm
              title='Project'
              children={<ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default FaqCreate
