import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FaLanguage } from 'react-icons/fa'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { socialMediaServices } from '../../../../services/common/socialMedias/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type SocialMediaFormData = {
  name: string
  code: string
  logo: string
  orderNumber: number
  minFollowerCountForLevelOne: number
  maxFollowerCountForLevelOne: number
  minFollowerCountForLevelTwo: number
  maxFollowerCountForLevelTwo: number
  minFollowerCountForLevelThree: number
  minEngagementRate: number
}

const SocialMediaUpdate = () => {
  const [socialMediaData, setSocialMediaData] = useState<any | null>(null)
  const navigate = useNavigate()

  const id = location.pathname.split('/social-media/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Title is required'),
    logo: Yup.string().required('Description is required'),
    orderNumber: Yup.number().required('Number is required'),
    minFollowerCountForLevelOne: Yup.number().required('Address is required'),
    maxFollowerCountForLevelOne: Yup.number().required('Person is required'),
    minFollowerCountForLevelTwo: Yup.number().required('E-mail is required'),
    maxFollowerCountForLevelTwo: Yup.number().required('Logo Url is required'),
    minFollowerCountForLevelThree: Yup.number().required('Termsheet is required'),
    minEngagementRate: Yup.number().required('Default is required'),
  })

  useEffect(() => {
    socialMediaServices.findOne(id).then((response) => {
      if (response) {
        setSocialMediaData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSocialMediaData({
      ...socialMediaData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SocialMediaFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: SocialMediaFormData) => {
    socialMediaServices.update(id, data).finally(() => {
      navigate('/social-media')
    })
  }

  if (!socialMediaData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='Social Media' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Social Media'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Name'
              error={errors.name}
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={socialMediaData.name}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Code'
              error={errors.code}
              children={
                <Input
                  type='text'
                  id='code'
                  {...register('code')}
                  value={socialMediaData.code}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Logo'
              error={errors.logo}
              children={
                <Input
                  type='text'
                  id='logo'
                  {...register('logo')}
                  value={socialMediaData.logo}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Order Number'
              error={errors.orderNumber}
              children={
                <Input
                  type='text'
                  id='orderNumber'
                  {...register('orderNumber')}
                  value={socialMediaData.orderNumber}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Min Follower Count For Level One'
              error={errors.minFollowerCountForLevelOne}
              children={
                <Input
                  type='text'
                  id='minFollowerCountForLevelOne'
                  {...register('minFollowerCountForLevelOne')}
                  value={socialMediaData.minFollowerCountForLevelOne}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Max Follower Count For Level One'
              error={errors.maxFollowerCountForLevelOne}
              children={
                <Input
                  type='text'
                  id='maxFollowerCountForLevelOne'
                  {...register('maxFollowerCountForLevelOne')}
                  value={socialMediaData.maxFollowerCountForLevelOne}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Min Follower Count For Level Two'
              error={errors.minFollowerCountForLevelTwo}
              children={
                <Input
                  type='text'
                  id='minFollowerCountForLevelTwo'
                  {...register('minFollowerCountForLevelTwo')}
                  value={socialMediaData.minFollowerCountForLevelTwo}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Max Follower Count For Level Two'
              error={errors.maxFollowerCountForLevelTwo}
              children={
                <Input
                  type='text'
                  id='maxFollowerCountForLevelTwo'
                  {...register('maxFollowerCountForLevelTwo')}
                  value={socialMediaData.maxFollowerCountForLevelTwo}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Min Follower Count For Level Three'
              error={errors.minFollowerCountForLevelThree}
              children={
                <Input
                  type='text'
                  id='minFollowerCountForLevelThree'
                  {...register('minFollowerCountForLevelThree')}
                  value={socialMediaData.minFollowerCountForLevelThree}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Min Engagement Rate'
              error={errors.minEngagementRate}
              children={
                <Input
                  type='text'
                  id='minEngagementRate'
                  {...register('minEngagementRate')}
                  value={socialMediaData.minEngagementRate}
                  onChange={handleInputChange}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default SocialMediaUpdate
