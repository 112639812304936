import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { MdQuestionAnswer } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { faqServices } from '../../../services/common/faqs/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const Faq = () => {
  const [faqData, setFaqsData] = useState<any[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    setFaqs()
  }, [])

  const setFaqs = () => {
    faqServices.findAll('4b0117c5-886f-42a3-8b23-4c8edbbb1974', 'en').then((response) => {
      if (response) {
        setFaqsData(response.data)
      } else {
        toast('An error occurred while loading the Faq data.')
      }
    })
  }

  const updateFaq = (id: string) => {
    navigate(`/faq/update/${id}`)
  }

  const detailFaq = (id: string) => {
    navigate(`/faq/${id}`)
  }
  const deleteFaq = (id: string) => {
    faqServices.remove(id).finally(() => {
      setFaqs()
    })
  }
  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Faq' secondButton='Add Faq' secondButtonLink='add' />
      <GeneralCard title='Faq List'>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'TITLE'} />
                <Th children={'SUMMARY'} />
                <Th children={'DESCRIPTION'} />
                <Th children={'ORDER NUMBER'} />
                <Th children={'LANGUAGE'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {faqData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailFaq(item.id)} cursor={'pointer'} children={item.title} />
                    <Td children={item.summary} />
                    <Td children={item.description} />
                    <Td children={item.orderNumber} />
                    <Td children={item.languageCode} />
                    <Td
                      children={<ActionButton deleteFunction={deleteFaq} id={item.id} updateFunction={updateFaq} />}
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Faq
