import { axiosInstance } from '../../axios'

export const socialMediaServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('social-medias', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`social-medias/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('social-medias')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`social-medias/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`social-medias/${id}`)
  },
  activateProject: (id: string, isActive: number) => {
    return axiosInstance.get(`social-medias/${id}/${isActive}`)
  },
}
