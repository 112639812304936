import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdMovie } from 'react-icons/md'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { videoLinkServices } from '../../../../services/common/videoLinks/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import VideoTypeSelect from '../../../../components/selects/VideoTypeSelect'
import { toast } from 'react-toastify'
import ProjectSelect from '../../../../components/selects/ProjectSelect'

type VideoLinkFormData = {
  name: string
  videoUrl: string
  thumbnailUrl: string
  languageCode: string
  type: string
  projectId: string
}

const VideoLinkUpdate = () => {
  const [selectedVideoType, setSelectedVideoType] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [videoLinkData, setVideoLinkData] = useState<any | null>(null)
  const navigate = useNavigate()

  const id = location.pathname.split('/video-link/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    videoUrl: Yup.string().required('Title is required'),
    thumbnailUrl: Yup.string().required('Description is required'),
  })

  useEffect(() => {
    videoLinkServices.findOne(id).then((response) => {
      if (response) {
        setVideoLinkData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoLinkData({
      ...videoLinkData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<VideoLinkFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: VideoLinkFormData) => {
    if (!data.type && !selectedVideoType) {
      toast('Video Type is required')
      return
    }

    if (!data.projectId && !selectedProject?.id) {
      toast('Project is required')
      return
    }

    if (!data.languageCode && !selectedLanguage?.code) {
      toast('Language is required')
      return
    }

    data.type = data.type ?? selectedVideoType
    data.projectId = data.projectId ?? selectedProject?.id
    data.languageCode = data.languageCode ?? selectedLanguage?.code

    videoLinkServices.update(id, data).finally(() => {
      navigate('/video-link')
    })
  }

  if (!videoLinkData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdMovie} pageName='Video Link' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Video Link'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.name}
              title='Name'
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={videoLinkData.name}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.videoUrl}
              title='Video URL'
              children={
                <Input
                  type='text'
                  id='videoUrl'
                  {...register('videoUrl')}
                  value={videoLinkData.videoUrl}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.thumbnailUrl}
              title='Thumbnail URL'
              children={
                <Input
                  type='text'
                  id='thumbnailUrl'
                  {...register('thumbnailUrl')}
                  value={videoLinkData.thumbnailUrl}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={videoLinkData.languageCode}
                />
              }
            />
            <CustomInputForm
              title='Type'
              children={
                <VideoTypeSelect
                  selectedVideoType={selectedVideoType}
                  setSelectedVideoType={setSelectedVideoType}
                  type={videoLinkData.type}
                />
              }
            />
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  id={videoLinkData.projectId}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default VideoLinkUpdate
