import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { MdBusinessCenter } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { companyServices } from '../../../services/common/companies/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const Company = () => {
  const [companyData, setCompaniesData] = useState<any[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    setCompanies()
  }, [])

  const setCompanies = () => {
    companyServices.findAll('1', '10').then((response) => {
      if (response) {
        setCompaniesData(response.data.items)
      } else {
        toast('An error occurred while loading the Company data.')
      }
    })
  }

  const updateCompany = (id: string) => {
    navigate(`/company/update/${id}`)
  }

  const detailCompany = (id: string) => {
    navigate(`/company/${id}`)
  }
  const deleteCompany = (id: string) => {
    companyServices.remove(id).finally(() => {
      setCompanies()
    })
  }
  return (
    <DefaultLayout>
      <NavCard icon={MdBusinessCenter} pageName='Company' secondButton='Add Company' secondButtonLink='add' />
      <GeneralCard title='Company List'>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'NAME'} />
                <Th children={'TITLE'} />
                <Th children={'PERSON'} />
                <Th children={'EMAIL'} />
                <Th children={'ESTABLISHED DATE'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {companyData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailCompany(item.id)} cursor={'pointer'} children={item.name} />
                    <Td children={item.title} />
                    <Td children={item.person} />
                    <Td children={item.email} />
                    <Td children={item.establishedDate} />
                    {/* <Td children={item.establishedDate.slice(0, 10)} /> */}
                    <Td
                      children={
                        <ActionButton deleteFunction={deleteCompany} id={item.id} updateFunction={updateCompany} />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Company
