import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { MdQuestionAnswer } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { faqServices } from '../../../../services/common/faqs/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import ProjectSelect from '../../../../components/selects/ProjectSelect'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type FaqFormData = {
  title: string
  summary: string
  description: string
  orderNumber: number
  projectId: string
  languageCode: string
}

const FaqUpdate = () => {
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [faqData, setFaqData] = useState<any | null>(null)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    summary: Yup.string().required('Summary is required'),
    description: Yup.string().required('Description is required'),
    orderNumber: Yup.number().required('Orner Number is required'),
  })

  const id = location.pathname.split('/faq/update/')[1]

  useEffect(() => {
    faqServices.findOne(id).then((response) => {
      if (response) setFaqData(response.data)
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFaqData({ ...faqData, [event.target.name]: event.target.value })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FaqFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: FaqFormData) => {
    data.projectId = data.projectId ?? selectedProject?.id ?? faqData.projectId
    data.languageCode = data.languageCode ?? selectedLanguage?.code ?? faqData.languageCode

    faqServices.update(id, data).finally(() => {
      navigate('/faq')
    })
  }

  if (!faqData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Faq' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Faq'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Title'
              children={
                <Input
                  type='text'
                  id='title'
                  {...register('title')}
                  value={faqData.title}
                  onChange={handleInputChange}
                />
              }
              error={errors.title}
            />
            <CustomInputForm
              title='Summary'
              children={
                <Input
                  type='text'
                  id='summary'
                  {...register('summary')}
                  value={faqData.summary}
                  onChange={handleInputChange}
                />
              }
              error={errors.summary}
            />
            <CustomInputForm
              title='Description'
              children={
                <Input
                  type='text'
                  id='description'
                  {...register('description')}
                  value={faqData.description}
                  onChange={handleInputChange}
                />
              }
              error={errors.summary}
            />
            <CustomInputForm
              title='Order Number'
              children={
                <Input
                  type='number'
                  id='orderNumber'
                  {...register('orderNumber')}
                  value={faqData.orderNumber}
                  onChange={handleInputChange}
                />
              }
              error={errors.summary}
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={faqData.languageCode}
                />
              }
              error={errors.summary}
            />
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  id={faqData.projectId}
                />
              }
              error={errors.summary}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default FaqUpdate
