import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

// Icons
import { MdOutlineManageAccounts } from 'react-icons/md'

// Services
import { employeeServices } from '../../../services/employees/index'

// Default
import DefaultLayout from '../../../layouts/DefaultLayout'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import EmployeeTypeSelect from '../../../components/selects/EmployeeTypeSelect'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import { useNavigate } from 'react-router-dom'

type EmployeeFormData = {
  firstName: string
  lastName: string
  type: string
  email: string
  address: string
}

const EmployeeUpdate = () => {
  const [selectedEmployeeType, setSelectedEmployeeType] = useState<any | null>(null)
  const [employeeData, setEmployeeData] = useState<any | null>(null)
  const employeeId = location.pathname.split('/manage-employee/update/')[1]
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
    address: Yup.string().required('Address is required'),
  })

  useEffect(() => {
    employeeServices.findOne(employeeId).then((response) => {
      if (response) {
        setEmployeeData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeData({
      ...employeeData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: EmployeeFormData) => {
    data.type = selectedEmployeeType

    employeeServices.update(employeeId, data).finally(() => {
      navigate(`/manage-employee/${employeeId}`)
    })
  }

  if (!employeeData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdOutlineManageAccounts} pageName='Employee' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard
          title='Update Employee'
          children={
            <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
              <CustomInputForm
                title='First Name'
                error={errors.firstName}
                children={
                  <Input
                    type='text'
                    id='firstName'
                    {...register('firstName')}
                    value={employeeData.firstName}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                title='Last Name'
                error={errors.lastName}
                children={
                  <Input
                    type='text'
                    id='lastName'
                    {...register('lastName')}
                    value={employeeData.lastName}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                title='Address'
                error={errors.address}
                children={
                  <Input
                    type='text'
                    id='address'
                    {...register('address')}
                    value={employeeData.address}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                title='Type'
                error={errors.type}
                children={
                  <EmployeeTypeSelect
                    selectedEmployeeType={selectedEmployeeType}
                    setSelectedEmployeeType={setSelectedEmployeeType}
                    type={employeeData.type}
                  />
                }
              />
              <CustomInputForm
                title='Email'
                error={errors.email}
                children={
                  <Input
                    type='email'
                    id='email'
                    {...register('email')}
                    value={employeeData.email}
                    onChange={handleInputChange}
                  />
                }
              />
            </Grid>
          }
        />
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children='Update' />
      </Flex>
    </DefaultLayout>
  )
}
export default EmployeeUpdate
