import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useState } from 'react'

import DefaultLayout from '../../../layouts/DefaultLayout'

import { MdAccountBox } from 'react-icons/md'

// Services
import { contentBankServices } from '../../../services/contentBank/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import ContentTypeSelect from '../../../components/selects/ContentTypeSelect'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import ProjectSelect from '../../../components/selects/ProjectSelect'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const ContentCreate = () => {
  const [selectedContentType, setSelectedContentType] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const navigate = useNavigate()

  const onSubmit = () => {
    if (!selectedProject?.id) {
      toast('Project is required')
      return
    }

    if (!selectedContentType) {
      toast('Media Type is required')
      return
    }

    const formData = new FormData()
    if (selectedFile !== null) {
      formData.append('file', selectedFile)
    }

    contentBankServices
      .create(selectedContentType, selectedProject.id, formData)
      .then((response) => {
        if (response) {
          navigate(`/gallery`)
        }
      })
      .catch((error) => {
        toast(error.request)
      })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    setSelectedFile(file)
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdAccountBox} pageName='Content Create' />

      <form>
        <GeneralCard title='Add Content'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Project'
              children={<ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}
            />
            <CustomInputForm
              title='Media Type'
              children={
                <ContentTypeSelect
                  selectedContentType={selectedContentType}
                  setSelectedContentType={setSelectedContentType}
                />
              }
            />
            <CustomInputForm title='Media' children={<Input type='file' onChange={handleFileChange} />} />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 40px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => onSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}

export default ContentCreate
