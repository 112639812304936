import { axiosInstance } from '../../axios'

export const contractTemplateServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('contract-templates', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`contract-templates/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('contract-templates')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`contract-templates/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`contract-templates/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`contract-templates/${id}`)
  },
}
