import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

import { FaLanguage } from 'react-icons/fa'

// Services
import { languageServices } from '../../../../services/common/languages/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import CustomCheckbox from '../../../../components/customFormElements/CustomCheckbox'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type LanguageFormData = {
  name: string
  code: string
  flag: string
  rtl: boolean
  orderNumber: number
}

const LanguageCreate = () => {
  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    flag: Yup.string().required('Flag is required'),
    rtl: Yup.boolean().required('Language is required'),
    orderNumber: Yup.number().required('Orner Number is required'),
  })

  const handleCheckChange = (newCheckState: boolean) => {
    setChecked(newCheckState)
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LanguageFormData>({ resolver: yupResolver(validationSchema) })

  const onSubmit = (data: LanguageFormData) => {
    languageServices.create(data).finally(() => {
      navigate('/language')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='Language' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Language'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.name}
              children={<Input type='text' id='name' placeholder='Name' {...register('name')} />}
              title='Name'
            />
            <CustomInputForm
              error={errors.flag}
              children={<Input type='text' id='flag' placeholder='Flag' {...register('flag')} />}
              title='Flag'
            />
            <CustomInputForm
              error={errors.code}
              children={<Input type='text' id='code' placeholder='Code' {...register('code')} />}
              title='Code'
            />
            <CustomInputForm
              error={errors.orderNumber}
              children={
                <Input type='number' id='orderNumber' placeholder='Order Number' {...register('orderNumber')} />
              }
              title='Order Number'
            />
            <CustomInputForm
              error={errors.rtl}
              children={
                <CustomCheckbox
                  defaultChecked={isChecked}
                  onCheckChange={handleCheckChange}
                  name={'RTL'}
                  register={register}
                  registerName='rtl'
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default LanguageCreate
