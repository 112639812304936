import { axiosInstance } from '../../axios'

export const countryServices = {
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`faqs/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get(`countries/`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`countries/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`countries/${id}`)
  },
}
