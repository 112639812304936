import { Button, Input, Flex, Grid } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

// Services
import { userEmailServices } from '../../../../services/users/userEmail/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'

type UserEmailFormData = {
  userId: string
  email: string
}

const UserEmailCreate = () => {
  const id = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserEmailFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: UserEmailFormData) => {
    data.userId = id

    userEmailServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            error={errors.email}
            title='Email'
            children={<Input type='text' id='email' {...register('email')} placeholder='Email' />}
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
      </Flex>
    </>
  )
}
export default UserEmailCreate
