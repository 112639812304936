import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MdMovie } from 'react-icons/md'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { videoLinkServices } from '../../../services/common/videoLinks/index'

// Components
import TruncatedTextWithTooltip from '../../../components/customFormElements/TruncatedTextWithTooltip'
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const VideoLink = () => {
  const [videoLinkData, setVideoLinksData] = useState<any[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    videoLinkServices.findAll().then((response) => {
      if (response) setVideoLinksData(response.data)
      else toast('An error occurred while loading the Video Link data.')
    })
  }, [])

  const updateVideoLink = (id: string) => {
    navigate(`/video-link/update/${id}`)
  }

  const detailVideoLink = (id: string) => {
    navigate(`/video-link/${id}`)
  }

  const deleteVideoLink = (id: string) => {
    videoLinkServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdMovie} pageName='Video Link' secondButton='Add Video Link' secondButtonLink='add' />
      <GeneralCard title='Video Link List' customColumn={{ base: 1, md: 1, lg: 1, '2xl': 1 }}>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'NAME'} />
                <Th children={'VIDEO URL'} />
                <Th children={'THUMBNAIL URL'} />
                <Th children={'LANGUAGE CODE'} />
                <Th children={'TYPE'} />
                <Th children={'PROJECT'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {videoLinkData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailVideoLink(item.id)} cursor={'pointer'} children={item.name} />
                    <Td children={<TruncatedTextWithTooltip text={item.videoUrl} />} />
                    <Td children={<TruncatedTextWithTooltip text={item.thumbnailUrl} />} />
                    <Td children={item.languageCode} />
                    <Td children={item.type} />
                    <Td children={item.projectId} />
                    <Td
                      children={
                        <ActionButton deleteFunction={deleteVideoLink} id={item.id} updateFunction={updateVideoLink} />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default VideoLink
