import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { GiLevelEndFlag } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { contractLevelServices } from '../../../services/common/contractLevels/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const ContractLevel = () => {
  const [contractLevelData, setContractLevelsData] = useState<any[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    setContractLevels()
  }, [])

  const setContractLevels = () => {
    contractLevelServices.findAll().then((response) => {
      if (response) {
        setContractLevelsData(response.data)
      } else {
        toast('An error occurred while loading the ContractLevel data.')
      }
    })
  }

  const updateContractLevel = (id: string) => {
    navigate(`/contract-level/update/${id}`)
  }

  const detailContractLevel = (id: string) => {
    navigate(`/contract-level/${id}`)
  }
  const deleteContractLevel = (id: string) => {
    contractLevelServices.remove(id).finally(() => {
      setContractLevels()
    })
  }

  if (!contractLevelData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={GiLevelEndFlag}
        pageName='Contract Level'
        secondButton='Add Contract Level'
        secondButtonLink='add'
      />
      <GeneralCard title='Contract Level List'>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'NAME'} />
                <Th children={'YEAR'} />
                <Th children={'LEVEL'} />
                <Th children={'FIRST VALUE'} />
                <Th children={'SECOND VALUE'} />
                <Th children={'THIRD VALUE'} />
                <Th children={'CURRENCY TYPE'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {contractLevelData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailContractLevel(item.id)} cursor={'pointer'} children={item.name} />
                    <Td children={item.year} />
                    <Td children={item.level} />
                    <Td children={item.firstValue} />
                    <Td children={item.secondValue} />
                    <Td children={item.thirdValue} />
                    <Td children={item.currencyType} />
                    <Td
                      children={
                        <ActionButton
                          deleteFunction={deleteContractLevel}
                          id={item.id}
                          updateFunction={updateContractLevel}
                        />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default ContractLevel
