import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { MdBusinessCenter } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { companyServices } from '../../../../services/common/companies/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type CompanyFormData = {
  name: string
  title: string
  description: string
  number: string
  address: string
  person: string
  email: string
  logoUrl: string
  establishedDate: string
}

const CompanyUpdate = () => {
  const [companyData, setCompanyData] = useState<any | null>(null)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    number: Yup.number().required('Number is required'),
    address: Yup.number().required('Address is required'),
    person: Yup.number().required('Person is required'),
    email: Yup.number().required('E-mail is required'),
    logoUrl: Yup.number().required('Logo URL is required'),
    establishedDate: Yup.number().required('Established Date is required'),
  })

  const id = location.pathname.split('/company/update/')[1]

  useEffect(() => {
    companyServices.findOne(id).then((response) => {
      if (response) setCompanyData(response.data)
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData({ ...companyData, [event.target.name]: event.target.value })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CompanyFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: CompanyFormData) => {
    companyServices.update(id, data).finally(() => {
      navigate('/company')
    })
  }

  if (!companyData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdBusinessCenter} pageName='Company' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Company'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Name'
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={companyData.name}
                  onChange={handleInputChange}
                />
              }
              error={errors.title}
            />
            <CustomInputForm
              title='Title'
              children={
                <Input
                  type='text'
                  id='title'
                  {...register('title')}
                  value={companyData.title}
                  onChange={handleInputChange}
                />
              }
              error={errors.title}
            />

            <CustomInputForm
              title='Description'
              children={
                <Input
                  type='text'
                  id='description'
                  {...register('description')}
                  value={companyData.description}
                  onChange={handleInputChange}
                />
              }
              error={errors.description}
            />
            <CustomInputForm
              title='Number'
              children={
                <Input
                  type='text'
                  id='number'
                  {...register('number')}
                  value={companyData.number}
                  onChange={handleInputChange}
                />
              }
              error={errors.number}
            />
            <CustomInputForm
              title='Address'
              children={
                <Input
                  type='text'
                  id='address'
                  {...register('address')}
                  value={companyData.address}
                  onChange={handleInputChange}
                />
              }
              error={errors.address}
            />
            <CustomInputForm
              title='Person'
              children={
                <Input
                  type='text'
                  id='person'
                  {...register('person')}
                  value={companyData.person}
                  onChange={handleInputChange}
                />
              }
              error={errors.person}
            />
            <CustomInputForm
              title='E-mail'
              children={
                <Input
                  type='text'
                  id='email'
                  {...register('email')}
                  value={companyData.email}
                  onChange={handleInputChange}
                />
              }
              error={errors.email}
            />
            <CustomInputForm
              title='Logo URL'
              children={
                <Input
                  type='text'
                  id='logoUrl'
                  {...register('logoUrl')}
                  value={companyData.logoUrl}
                  onChange={handleInputChange}
                />
              }
              error={errors.logoUrl}
            />
            <CustomInputForm
              title='Established Date'
              children={
                <Input
                  type='text'
                  id='establishedDate'
                  {...register('establishedDate')}
                  value={companyData.establishedDate}
                  onChange={handleInputChange}
                />
              }
              error={errors.establishedDate}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default CompanyUpdate
