import { TableContainer, useDisclosure, Table, Tbody, Thead, Td, Th, Tr, Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { FaImages } from 'react-icons/fa'

// Services
import { contentBankServices } from '../../services/contentBank/index'

import DefaultLayout from '../../layouts/DefaultLayout'
import ContentDetail from './detail/index'

// Components
import ActionButton from '../../components/customFormElements/ActionButtons'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../components/shareds/LoadingComponent'
import NavCard from '../../components/cards/navCard/NavigateCard'
import CustomModal from '../../components/modal/CustomModal'

const ContentBank = () => {
  const [contentBankData, setContentBankData] = useState<any[]>([])
  const [contentId, setContentId] = useState<string>()

  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    contentBankServices.query('image', false, 1, 10).then((response) => {
      if (response) {
        setContentBankData(response.data.items)
      } else {
        toast('An error occurred while loading the User Email data.')
      }
    })
  }, [])

  const updateContent = (id: string) => {
    setContentId(id)
  }

  const detailContent = (id: string) => {
    setContentId(id)
    onOpenDetail()
  }

  const deleteContent = (id: string) => {
    contentBankServices.remove(id).finally(() => {
      setContentId(id)
    })
  }

  if (!contentBankData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={FaImages}
        pageName='Gallery List'
        secondButton='Add Content'
        secondButtonLink='add'
        key={'Add_Content'}
      />
      <GeneralCard title='Gallery'>
        <TableContainer maxH='30em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'Preview'} />
                <Th children={'Media Type'} />
                <Th children={'Private'} />
                <Th children={'Organic'} />
                <Th children={'Status'} />
                <Th children={'Action'} />
              </Tr>
            </Thead>
            {contentBankData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td
                      onClick={() => detailContent(item.id)}
                      cursor={'pointer'}
                      color={'blue'}
                      children={<Image src={item.mediaUrl} borderRadius='10px' boxSize='100px' />}
                    />
                    <Td children={item.mediaType} />
                    <Td children={item.isPrivate.toString()} />
                    <Td children={item.isOrganic.toString()} />
                    <Td children={item.status} />
                    <Td
                      children={
                        <ActionButton deleteFunction={deleteContent} updateFunction={updateContent} id={item.id} />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>

      {
        <CustomModal
          title='Content Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'Content_Detail'}
          children={<ContentDetail id={contentId ? contentId : ''} />}
        />
      }
    </DefaultLayout>
  )
}

export default ContentBank
