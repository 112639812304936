import { axiosInstance, axiosInstanceForMedia } from '../axios'

export const contentBankServices = {
  create: (mediaType: string, projectId: string, formData: any) => {
    return axiosInstanceForMedia.post(`content-bank/contents?mediaType=${mediaType}&projectId=${projectId}`, formData)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`content-bank/${id}`, requestModel)
  },
  paymentStatus: (id: string, status: string) => {
    return axiosInstance.put(`content-bank/paymentStatus/${id}/${status}`)
  },
  query: (type: string, isOrganic: boolean, page: number, limit: number) => {
    return axiosInstance.get(`content-bank/contents?type=${type}&isOrganic=${isOrganic}&page=${page}&limit=${limit}`)
  },
  findAll: () => {
    return axiosInstance.get('content-bank')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`content-bank/contents/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`content-bank/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`content-bank/${id}`)
  },
}
