import { useEffect, useState } from 'react'

// Icons
import RegisteredIcon from '../../assets/icons/icon@registered.svg'
import ReferredIcon from '../../assets/icons/icon@referred.svg'
import AwaitingIcon from '../../assets/icons/icon@awaiting.svg'
import DidntIcon from '../../assets/icons/icon@didnt.svg'
import UncontractIcon from '../../assets/icons/icon@uncontract.svg'
import SignedIcon from '../../assets/icons/icon@signed.svg'
import CancelledIcon from '../../assets/icons/icon@cancelled.svg'
import { MdDashboard } from 'react-icons/md'

import DefaultLayout from '../../layouts/DefaultLayout'
import { getToken } from '../../utils/local-storage'

// Services
import { userServices } from '../../services/users'

// Components
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../components/shareds/LoadingComponent'
import NavCard from '../../components/cards/navCard/NavigateCard'
import MidCard from '../../components/cards/midCard/MidCard'

const Dashboard = () => {
  const [statusData, setStatus] = useState<any | null>(null)

  useEffect(() => {
    const token = getToken()
    if (token) {
      userServices.statusList().then((response) => {
        if (response.data) {
          setStatus(response.data)
        }
      })
    }
  }, [])

  if (!statusData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdDashboard} pageName='Dashboard' key={'Dashboard'} />
      <GeneralCard title='STATS' customColumn={{ base: 1, md: 2, lg: 3, '2xl': 4 }}>
        <MidCard
          icon={RegisteredIcon}
          name={'New Leads'}
          value={statusData[11].total}
          link={'/influencers?contractStatus=registered'}
          key={statusData[10]}
        />
        <MidCard
          icon={SignedIcon}
          name={'Contract Signed'}
          value={statusData[3].total}
          link={'/influencers?contractStatus=signed'}
          key={statusData[2].total}
        />
        <MidCard
          icon={AwaitingIcon}
          name={'Contract Not Signed'}
          value={statusData[5].total}
          link={'/influencers?contractStatus=notSigned'}
          key={statusData[4].total}
        />
        <MidCard
          icon={UncontractIcon}
          name={'Expired Contracts'}
          value={statusData[13].total}
          link={'/influencers?contractStatus=expired'}
          key={statusData[12].total}
        />
        <MidCard
          icon={CancelledIcon}
          name={'Cancelled Contracts'}
          value={statusData[7].total}
          link={'/influencers?contractStatus=cancelled'}
          key={statusData[6].total}
        />
        <MidCard
          icon={ReferredIcon}
          name={'Referred'}
          value={statusData[9].total}
          link={'/influencers?contractStatus=referred'}
          key={statusData[8].total}
        />
        <MidCard
          icon={DidntIcon}
          name={'Special Contracts'}
          value={statusData[15].total}
          link={'/influencers?contractStatus=special'}
          key={statusData[14].total}
        />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Dashboard
