import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { FaLanguage } from 'react-icons/fa'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { languageServices } from '../../../services/common/languages/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const Language = () => {
  const [languageData, setLanguagesData] = useState<any[]>([])
  const navigate = useNavigate()

  const setLanguages = () => {
    languageServices.findAll().then((response) => {
      if (response) setLanguagesData(response.data)
      else toast('An error occurred while loading the Language data.')
    })
  }

  useEffect(() => {
    setLanguages()
  }, [])

  const detailLanguage = (id: string) => {
    navigate(`/language/${id}`)
  }

  const updateLanguage = (data: any) => {
    navigate(`/language/update/${data}`)
  }

  const deleteLanguage = (id: string) => {
    languageServices.remove(id).finally(() => {
      setLanguages()
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='Language' secondButton='Add Language' secondButtonLink='add' />
      <GeneralCard title='Language List'>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'NAME'} />
                <Th children={'CODE'} />
                <Th children={'FLAG'} />
                <Th children={'RTL'} />
                <Th children={'ORDER NUMBER'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {languageData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailLanguage(item.id)} cursor={'pointer'} children={item.name} />
                    <Td children={item.code} />
                    <Td children={item.flag} />
                    <Td children={item.rtl} />
                    <Td children={item.orderNumber} />
                    <Td
                      children={
                        <ActionButton deleteFunction={deleteLanguage} id={item.id} updateFunction={updateLanguage} />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Language
