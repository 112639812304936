import {
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Text,
  Link,
  Select,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Tooltip,
} from '@chakra-ui/react'
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import ContractStatusSelect from '../../selects/ContractStatusSelectAndUpdate'
import AssignedSelectAndUpdate from '../../selects/AssignedSelectAndUpdate'

interface MainTableProps {
  setSelectedPageNumber: (pageNumber: any) => void
  setSelectedPageLimit: (pageLimit: any) => void
  userData: any[]
}

export default function MainTable(props: MainTableProps) {
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(1)

  const [canPreviousPage, setCanPreviousPage] = useState(false)
  const [canNextPage, setCanNextPage] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    const pageCount = Math.ceil(props.userData.length / pageLimit)
    if (pageCount !== totalPage) setTotalPage(pageCount)
    setCanPreviousPage(pageIndex > 1)
    setCanNextPage(pageIndex < totalPage)

    props.setSelectedPageNumber(pageIndex)
    props.setSelectedPageLimit(pageLimit)
  }, [props.userData, pageIndex, totalPage])

  const handleOnSubmit = (id: any) => {
    navigate(`/influencer/${id}`)
  }

  const onChange = (value: string) => {
    const page = value ? Number(value) - 1 : 0
    gotoPage(page)
  }

  const gotoPage = (page: number | undefined) => {}
  const previousPage = () => {
    setPageIndex(pageIndex - 1)
  }
  const nextPage = () => {
    setPageIndex(pageIndex + 1)
  }

  const userData = props.userData
  return (
    <TableContainer>
      <Table variant='simple' size={'sm'}>
        <TableCaption bg={'white'} borderRadius={'10px'}>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex>
              <Tooltip label='First Page'>
                <IconButton
                  onClick={() => gotoPage(1)}
                  isDisabled={!canPreviousPage}
                  aria-label='s'
                  icon={<ArrowLeftIcon h={3} w={3} />}
                  mr={4}
                />
              </Tooltip>
              <Tooltip label='Previous Page'>
                <IconButton
                  onClick={() => previousPage()}
                  isDisabled={!canPreviousPage}
                  aria-label='s'
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>
            </Flex>

            <Flex alignItems='center'>
              <Text flexShrink='0' mr={8}>
                Page <Text fontWeight='bold' as='span' children={pageIndex} /> of{' '}
                <Text fontWeight='bold' as='span' children={totalPage} />
              </Text>
              <Text flexShrink='0' children={'Go to page:'} />{' '}
              <NumberInput ml={2} mr={8} w={28} min={1} max={totalPage} onChange={onChange} defaultValue={pageIndex}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select
                w={32}
                value={pageLimit}
                onChange={(e) => {
                  setPageLimit(Number(e.target.value))
                }}
              >
                {[2, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label='Next Page'>
                <IconButton
                  onClick={() => nextPage()}
                  isDisabled={!canNextPage}
                  aria-label='s'
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
              <Tooltip label='Last Page'>
                <IconButton
                  onClick={() => gotoPage(totalPage - 1)}
                  isDisabled={!canNextPage}
                  aria-label='s'
                  icon={<ArrowRightIcon h={3} w={3} />}
                  ml={4}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </TableCaption>
        <Thead bg={'white'} position={'sticky'} top={'0px'} borderRadius={'10px'} zIndex={1}>
          <Tr>
            <Th children={'NAME'} />
            <Th children={'SOCIAL MEDIA'} />
            <Th children={'FOLLOWER NUMBER'} />
            <Th children={'ACCOUNT DATE'} />
            <Th children={'CONTRACTED STATUS'} />
            <Th children={'OPENER'} />
          </Tr>
        </Thead>
        {userData.map((item) => {
          return (
            <Tbody paddingBottom={'20px'} key={item.id}>
              <Tr>
                <Td
                  onClick={() => handleOnSubmit(item.id)}
                  cursor={'pointer'}
                  color={'blue'}
                  children={<Link children={item.firstName + ' ' + item.lastName} />}
                />
                <Td children={item.mainSocialMediaName} />
                <Td children={item.followerNumber} />
                <Td children={item.createdDate.slice(0, 10)} />
                <Td children={<ContractStatusSelect userId={item.id} status={item.contractStatus} />} />
                <Td children={<AssignedSelectAndUpdate assignedType='opener' userId={item.id} id={item.openerId} />} />
              </Tr>
            </Tbody>
          )
        })}

        <Tfoot paddingTop={'20px'} bg={'white'} borderRadius={'10px'} />
      </Table>
    </TableContainer>
  )
}
