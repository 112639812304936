import { axiosInstance } from '../../axios'

export const videoLinkServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('video-links', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`video-links/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('video-links')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`video-links/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`video-links/${id}`)
  },
}
