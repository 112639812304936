import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdAccountBox } from 'react-icons/md'
import { useEffect, useState } from 'react'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { userServices } from '../../../services/users'

// Components
import GeneralFilter from '../../../components/generalFilter/AlternativeGeneralFilter'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import UserTable from '../../../components/tables/userTable/UserTable'

const User = () => {
  const [userData, setUsersData] = useState<any[]>([])
  const [inputValue, setInputValue] = useState('')
  const [contractStatus, setContractStatus] = useState<any | null>(null)
  const [opener, setOpener] = useState<any | null>(null)
  const [owner, setOwner] = useState<any | null>(null)
  const [closer, setCloser] = useState<any | null>(null)
  const [userType, setUserType] = useState<any | null>(null)
  const [country, setCountry] = useState<any | null>(null)
  const [user, setUser] = useState<any | null>(null)
  const [sortByValue, setSortBy] = useState<any | null>(null)
  const [pageNumber, setPageNubmer] = useState('')
  const [pageLimit, setPageLimit] = useState('')

  useEffect(() => {
    const requestModel = {
      openerId: opener ? opener.id : '',
      ownerId: owner ? owner.id : '',
      closerId: closer ? closer.id : '',
      referenceId: user ? user.id : '',
      userType: userType ? userType : '',
      sortDirection: sortByValue ? sortByValue.value : '',
      searchTag: inputValue.length >= 3 ? inputValue : '',
      page: pageNumber ? pageNumber : 1,
      limit: pageLimit ? pageLimit : 10,
      contractStatus: contractStatus ? contractStatus : '',
      countryCode: country ? country.alpha2Code : '',
    }

    userServices.queryGeneralFilter(requestModel).then((response) => {
      if (response) {
        setUsersData(response.data.items)
      }
    })
  }, [contractStatus, opener, owner, closer, inputValue, userType, country, user, sortByValue, pageNumber, pageLimit])

  return (
    <DefaultLayout>
      <NavCard
        icon={MdAccountBox}
        pageName='Influencer'
        firstButton='Invite'
        firstButtonLink='invite-influencer'
        secondButton='Add Influencer'
        secondButtonLink='add'
        key={'Nav_Card'}
      />
      <GeneralCard title=''>
        <GeneralFilter
          setSearchInput={setInputValue}
          setSelectedContractStatus={setContractStatus}
          setSelectedCloser={setCloser}
          setSelectedOpener={setOpener}
          setSelectedOwner={setOwner}
          setSelectedUserType={setUserType}
          setSelectedCountry={setCountry}
          setSelectedUser={setUser}
          setSelectedSortBy={setSortBy}
        />
      </GeneralCard>

      <GeneralCard title='Influencer List'>
        <UserTable setSelectedPageLimit={setPageLimit} setSelectedPageNumber={setPageNubmer} userData={userData} />
        <ToastContainer />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default User
