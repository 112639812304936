import { MdBusinessCenter } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { companyServices } from '../../../../services/common/companies'

// Components
import UpdateDeleteButton from '../../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

const CompanyDetail = () => {
  const [companyData, setCompanyData] = useState<any | null>(null)
  const id = location.pathname.split('/company/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    companyServices.findOne(id).then((response) => {
      setCompanyData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    companyServices.remove(id).finally(() => {
      navigate('/company')
    })
  }

  if (!companyData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdBusinessCenter} pageName='Company' />
      <GeneralCard title='Company Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Name' lowerText={companyData.name} />
          <DetailCard upperText='Title' lowerText={companyData.title} />
          <DetailCard upperText='Description' lowerText={companyData.description} />
          <DetailCard upperText='Number' lowerText={companyData.number} />
          <DetailCard upperText='Address' lowerText={companyData.address} />
          <DetailCard upperText='Person' lowerText={companyData.person} />
          <DetailCard upperText='E-mail' lowerText={companyData.email} />
          <DetailCard upperText='Logo URL' lowerText={companyData.logoUrl} />
          <DetailCard upperText='Established Date' lowerText={companyData.establishedDate} />
        </Grid>
      </GeneralCard>
      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl={'/company/update'} />
    </DefaultLayout>
  )
}

export default CompanyDetail
