import { axiosInstance } from '../../axios/index'

export const employeeTypeServices = {
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`employee/employee-types/${id}`, requestModel)
  },
  findEmployeesByType: (type: string) => {
    return axiosInstance.get(`employee/employee-types/${type}`)
  },
  findAll: () => {
    return axiosInstance.get(`employee/employee-types/`)
  },
}
