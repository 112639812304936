import { Box, Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

// Services
import { userNoteServices } from '../../../../services/users/userNote/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import NoteTypeSelect from '../../../../components/selects/NoteTypeSelect'

type UserNoteFormData = {
  userId: string
  title: string
  description: string
  noteType: string
}

const UserNoteUpdate = (props: { id: string; closeModal: () => void }) => {
  const [selectedNoteType, setSelectedNoteType] = useState<any | null>(null)
  const [userNote, setUserNoteData] = useState<any | null>(null)
  const userId = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  })

  useEffect(() => {
    userNoteServices.findOne(props.id).then((response) => {
      if (response) {
        setUserNoteData(response.data)
        setSelectedNoteType(response.data.noteType)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserNoteData({
      ...userNote,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserNoteFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: UserNoteFormData) => {
    data.userId = userId
    data.noteType = selectedNoteType

    userNoteServices.update(props.id, data).finally(() => {
      props.closeModal()
    })
  }

  if (!userNote) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            error={errors.title}
            title='Title'
            children={
              <Input
                type='text'
                id='title'
                {...register('title')}
                placeholder='Title'
                value={userNote.title}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.description}
            title='Description'
            children={
              <Input
                id='description'
                {...register('description')}
                placeholder='Description'
                size={'sm'}
                value={userNote.description}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.title}
            title='Note Type'
            children={
              <NoteTypeSelect
                selectedNoteType={selectedNoteType}
                setSelectedNoteType={setSelectedNoteType}
                type={userNote.noteType}
              />
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </Box>
  )
}
export default UserNoteUpdate
