import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const PostingDayData = [
  { id: 1, text: 'MONDAY', value: 'mon' },
  { id: 2, text: 'TUESDAY', value: 'tues' },
  { id: 3, text: 'WEDNESDAY', value: 'wed' },
  { id: 4, text: 'THURSDAY', value: 'thurs' },
  { id: 5, text: 'FRIDAY', value: 'fri' },
  { id: 6, text: 'SATURDAY', value: 'sat' },
  { id: 7, text: 'SUNDAY', value: 'sun' },
]

interface ArrayObjectSelectState {
  selectedPostingDay: any | null
  setSelectedPostingDay: (postingDay: any | null) => void
  selectPostingDay: string
}

const PostingDaySelect: React.FC<ArrayObjectSelectState> = ({
  selectedPostingDay,
  setSelectedPostingDay,
  selectPostingDay,
}) => {
  const [days, setPostingDay] = useState<any[]>([])

  useEffect(() => {
    setPostingDay(PostingDayData)
    if (selectPostingDay) {
      const postingDay = PostingDayData.find((item) => item.value === selectPostingDay)
      if (postingDay) setSelectedPostingDay(postingDay)
    }
  }, [selectPostingDay, setSelectedPostingDay])

  return (
    <Select
      value={selectedPostingDay}
      onChange={(option: any | null) => {
        setSelectedPostingDay(option)
      }}
      getOptionLabel={(option: any) => option.text}
      getOptionValue={(option: any) => option.value}
      options={days}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'SELECT POSTING DAY'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default PostingDaySelect
