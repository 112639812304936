import { Grid, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Button, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Icons
import { MdOutlineManageAccounts } from 'react-icons/md'

// Services
import { employeeServices } from '../../../services/employees/index'
import { employeeRoleServices } from '../../../services/employees/employeeRoles/index'

// Layouts
import DefaultLayout from '../../../layouts/DefaultLayout'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import AlertDialogComponent from '../../../components/dialog/AlertDialogComponent'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavigateCard from '../../../components/cards/navCard/NavigateCard'
import DetailCard from '../../../components/cards/detailCard/DetailCard'
import CustomModal from '../../../components/modal/CustomModal'
import EmployeeRoleCreate from '../employeeRole'

const EmployeeDetail = () => {
  const { isOpen: isOpenEmployeeRole, onOpen: onOpenEmployeeRole, onClose: onCloseEmployeeRole } = useDisclosure()
  const [employeeData, setEmployeeData] = useState<any | null>(null)
  const [employeeRoleId, setEmployeeRoleId] = useState<string>('')
  const [roleData, setRoleData] = useState<any[]>([])

  const [isOpenAlert, setIsOpen] = useState(false)
  const onCloseAlert = () => setIsOpen(false)
  const cancelRef = useRef<HTMLButtonElement>(null)

  const [isOpenRoleAlert, setIsOpenRole] = useState(false)
  const onCloseRoleAlert = () => setIsOpenRole(false)
  const cancelRefRole = useRef<HTMLButtonElement>(null)

  const navigate = useNavigate()

  const employeeId = location.pathname.split('/manage-employee/')[1]

  useEffect(() => {
    employeeServices.findOne(employeeId).then((response) => {
      setEmployeeData(response.data)
    })
  }, [])

  useEffect(() => {
    employeeRoleServices.findEmployeeById(employeeId).then((response) => {
      setRoleData(response.data)
    })
  }, [])

  const deleteEmployee = () => {
    employeeServices.remove(employeeId).finally(() => {
      onCloseAlert()
      navigate('/manage-employee')
    })
  }

  const deleteEmployeeRole = () => {
    employeeRoleServices.remove(employeeRoleId).finally(() => {
      onCloseRoleAlert()
    })
  }

  const deleteFunction = () => {
    setIsOpen(true)
  }

  const deleteRoleFunction = (employeeRoleId: string) => {
    setEmployeeRoleId(employeeRoleId)

    setIsOpenRole(true)
  }

  if (!employeeData) {
    return <LoadingComponent />
  }

  return (
    <>
      <DefaultLayout>
        <NavigateCard
          icon={MdOutlineManageAccounts}
          pageName='Emloyee'
          firstButton='Update'
          firstButtonLink={`/manage-employee/update/${employeeId}`}
          secondButton='Delete'
          secondClickAction={deleteFunction}
        />
        <GeneralCard title='Employee Detail'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm children={<DetailCard upperText='First Name' lowerText={employeeData.firstName} />} />
            <CustomInputForm children={<DetailCard upperText='Last Name' lowerText={employeeData.lastName} />} />
            <CustomInputForm children={<DetailCard upperText='Address' lowerText={employeeData.address} />} />
            <CustomInputForm children={<DetailCard upperText='Type' lowerText={employeeData.type} />} />
            <CustomInputForm children={<DetailCard upperText='E-mail' lowerText={employeeData.email} />} />
          </Grid>
          <>
            <AlertDialogComponent
              isOpen={isOpenAlert}
              onClose={onCloseAlert}
              cancelRef={cancelRef}
              onConfirm={deleteEmployee}
            />
          </>
          <>
            <AlertDialogComponent
              isOpen={isOpenRoleAlert}
              onClose={onCloseRoleAlert}
              cancelRef={cancelRefRole}
              onConfirm={deleteEmployeeRole}
            />
          </>
        </GeneralCard>

        <GeneralCard title='Employee Role'>
          <TableContainer maxH='20em' overflowY='auto'>
            <Table variant='simple' size={'sm'}>
              <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
                <Tr>
                  <Th children={'Role'} />
                  <Th children={'Action'} />
                </Tr>
              </Thead>
              {roleData.map((item) => {
                return (
                  <Tbody paddingBottom={'20px'} key={item.id}>
                    <Tr>
                      <Td children={item.roleTitle} />
                      <Td
                        children={
                          <Button
                            onClick={() => deleteRoleFunction(item.id)}
                            colorScheme='red'
                            children='Delete'
                            size={'sm'}
                          />
                        }
                      />
                    </Tr>
                  </Tbody>
                )
              })}
            </Table>
          </TableContainer>
          <Button colorScheme='red' onClick={onOpenEmployeeRole} mt={'20px'} children={'Create'} w={'25%'} />
          <CustomModal
            title='Employee Role Create'
            isOpen={isOpenEmployeeRole}
            onClose={onCloseEmployeeRole}
            key={'Employee_Role_Create'}
            size={'xl'}
            children={<EmployeeRoleCreate employeeId={employeeId} />}
          />
        </GeneralCard>
      </DefaultLayout>
    </>
  )
}
export default EmployeeDetail
