import { axiosInstance } from '../../axios'

export const companyServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('companies', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`companies/${id}`, requestModel)
  },
  findAll: (page: string, limit: string) => {
    return axiosInstance.get(`companies?page=${page}&limit=${limit}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`companies/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`companies/${id}`)
  },
}
