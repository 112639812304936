import { axiosInstance } from '../../axios'

export const languageServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('languages', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`languages/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('languages')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`languages/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`languages/${id}`)
  },
}
