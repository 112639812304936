import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MdMovie } from 'react-icons/md'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { videoLinkServices } from '../../../../services/common/videoLinks/index'

// Components
import UpdateDeleteButton from '../../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

const VideoLinkDetail = () => {
  const [videoLinkData, setVideoLinkData] = useState<any | null>(null)

  const id = location.pathname.split('/video-link/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    videoLinkServices.findOne(id).then((response) => {
      setVideoLinkData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    videoLinkServices.remove(id).finally(() => {
      navigate('/video-link')
    })
  }

  if (!videoLinkData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdMovie} pageName='Video Link' />

      <GeneralCard title='Video Link Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Name' lowerText={videoLinkData.name} />
          <DetailCard upperText='Video URL' lowerText={videoLinkData.videoUrl} />
          <DetailCard upperText='Thumbnail URL' lowerText={videoLinkData.thumbnailUrl} />
          <DetailCard upperText='Type' lowerText={videoLinkData.type} />
          <DetailCard upperText='Language' lowerText={videoLinkData.languageCode} />
          <DetailCard upperText='Project' lowerText={videoLinkData.projectId} />
        </Grid>
      </GeneralCard>

      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl='/video-link/update' />
    </DefaultLayout>
  )
}

export default VideoLinkDetail
