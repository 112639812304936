import { Grid, GridItem, VStack, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { styles } from './styles'

export default function StatusCard(props: { icon: string; name: string; value: number; link: string }) {
  const navigate = useNavigate()
  return (
    <Grid
      sx={styles.grid}
      templateRows='repeat(1, 1fr)'
      templateColumns='repeat(5, 1fr)'
      onClick={() => navigate(props.link)}
    >
      <GridItem
        rowSpan={2}
        colSpan={1}
        children={<VStack children={<Image sx={styles.image} src={props.icon} alt={props.name} />} />}
      />
      <GridItem colSpan={4} sx={styles.value} children={<Text children={props.value} />} />
      <GridItem colSpan={4} sx={styles.text} children={<Text children={props.name} />} />
    </Grid>
  )
}
