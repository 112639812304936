import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { contractLevelServices } from '../../services/contracts/contractLevel/index'
import { contractSignServices } from '../../services/users/userContract'
interface ContractLevelData {
  id: string
  name: string
  year: string
  level: number
  secondValue: string
}

interface ArrayObjectSelectState {
  selectedContractLevel: ContractLevelData | null
  setSelectedContractLevel: (contractLevel: ContractLevelData | null) => void
  selectContractLevel: string
  userId?: string
  token?: string
}

const ContractLevelSelect: React.FC<ArrayObjectSelectState> = ({
  selectedContractLevel,
  setSelectedContractLevel,
  selectContractLevel,
  userId,
  token,
}) => {
  const [contractLevels, setContractLevel] = useState<ContractLevelData[]>([])

  useEffect(() => {
    const loadContractLevels = async () => {
      let response
      if (userId) {
        response = await contractLevelServices.findByUserId(userId)
      } else if (token) {
        response = await contractSignServices.findContractLevel(token)
      }

      if (response) {
        const contractLevelData = response.data as ContractLevelData[]
        setContractLevel(
          contractLevelData.map<ContractLevelData>((item) => {
            return {
              id: item.id,
              name: item.name,
              year: item.year,
              level: item.level,
              secondValue: item.secondValue,
            }
          }),
        )
        if (selectContractLevel) {
          const contractLevel = contractLevelData.find((item) => item.id === selectContractLevel)
          if (contractLevel) setSelectedContractLevel(contractLevel)
        }
      } else {
        toast('An error occurred while loading the Contract Level data.')
      }
    }
    loadContractLevels()
  }, [selectContractLevel, setSelectedContractLevel])

  return (
    <Select
      value={selectedContractLevel}
      onChange={(option: ContractLevelData | null) => {
        setSelectedContractLevel(option)
      }}
      getOptionLabel={(option: ContractLevelData) => option.year + ' Year' + ' ' + option.secondValue}
      getOptionValue={(option: ContractLevelData) => option.id}
      options={contractLevels}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'SELECT CONTRACT LEVEL'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default ContractLevelSelect
