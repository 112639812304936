import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { MdMovie } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { videoLinkServices } from '../../../../services/common/videoLinks/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import VideoTypeSelect from '../../../../components/selects/VideoTypeSelect'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import ProjectSelect from '../../../../components/selects/ProjectSelect'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type VideoTypeFormData = {
  name: string
  videoUrl: string
  thumbnailUrl: string
  languageCode: string
  type: string
  projectId: string
}

const VideoTypeCreate = () => {
  const [selectedVideoType, setSelectedVideoType] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    videoUrl: Yup.string().required('Thumbnail URL is required'),
    thumbnailUrl: Yup.string().required('Video URL is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<VideoTypeFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: VideoTypeFormData) => {
    if (!data.type && !selectedVideoType) {
      toast('Video Type is required')
      return
    }

    if (!data.projectId && !selectedProject?.id) {
      toast('Project is required')
      return
    }

    if (!data.languageCode && !selectedLanguage?.code) {
      toast('Language is required')
      return
    }

    data.type = data.type ?? selectedVideoType
    data.projectId = data.projectId ?? selectedProject?.id
    data.languageCode = data.languageCode ?? selectedLanguage?.code

    videoLinkServices.create(data).finally(() => {
      navigate('/video-link')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdMovie} pageName='Video Type' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Video Type'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={6} margin={'20px'}>
            <CustomInputForm
              title='Name'
              error={errors.name}
              children={<Input type='text' id='name' {...register('name')} />}
            />
            <CustomInputForm
              title='Video URL'
              error={errors.videoUrl}
              children={<Input type='text' id='videoUrl' {...register('videoUrl')} />}
            />
            <CustomInputForm
              title='Thumbnail URL'
              error={errors.thumbnailUrl}
              children={<Input type='text' id='thumbnailUrl' {...register('thumbnailUrl')} />}
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
              }
            />
            <CustomInputForm
              title='Video Type'
              children={
                <VideoTypeSelect selectedVideoType={selectedVideoType} setSelectedVideoType={setSelectedVideoType} />
              }
            />
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} id='' />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default VideoTypeCreate
