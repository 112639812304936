import { Box, Button, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IconType } from 'react-icons'
import Hero from '../../heros/Hero'
import { styles } from './styles'

export default function NavigateCard(props: {
  icon: IconType
  pageName: string
  firstButton?: string | ''
  firstButtonLink?: string | ''
  firstClickAction?: () => void
  secondButton?: string | ''
  secondButtonLink?: string | ''
  secondClickAction?: () => void
}) {
  const navigate = useNavigate()
  return (
    <Box margin={'0px 20px 0px 20px'}>
      <Grid h='120px' templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={2}>
        <GridItem
          rowSpan={2}
          colSpan={2}
          sx={styles.hero}
          children={<Hero icon={props.icon} label={props.pageName} />}
        />
        <GridItem colSpan={2} />
        <GridItem colSpan={2} />
        <GridItem colSpan={2} />

        <GridItem
          colSpan={2}
          children={
            <Flex justify='flex-end'>
              {props.firstButton && props.firstButton.length > 1 ? (
                <Button
                  colorScheme='pink'
                  mr={2}
                  size='lg'
                  onClick={() => {
                    if (props.firstClickAction) {
                      props.firstClickAction()
                    } else {
                      navigate(props.firstButtonLink ? props.firstButtonLink : '')
                    }
                  }}
                  children={props.firstButton}
                />
              ) : (
                ''
              )}
              {props.secondButton && props.secondButton.length > 1 ? (
                <Button
                  colorScheme='red'
                  mr={2}
                  size='lg'
                  onClick={() => {
                    if (props.secondClickAction) {
                      props.secondClickAction()
                    } else {
                      navigate(props.secondButtonLink ? props.secondButtonLink : '')
                    }
                  }}
                  children={props.secondButton}
                />
              ) : (
                ''
              )}
            </Flex>
          }
        />
      </Grid>
    </Box>
  )
}
