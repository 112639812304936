import { useEffect, useState } from 'react'
import { Image } from '@chakra-ui/react'

// Services
import { contentBankServices } from '../../../services/contentBank/index'

// Components
import LoadingComponent from '../../../components/shareds/LoadingComponent'

const ContentDetail = (props: { id: string }) => {
  const [contentData, setContentData] = useState<any | null>(null)

  useEffect(() => {
    contentBankServices.findOne(props.id).then((response) => {
      setContentData(response.data)
    })
  }, [])

  if (!contentData) {
    return <LoadingComponent />
  }

  return (
    <>
      <Image src={contentData.mediaUrl} />
    </>
  )
}
export default ContentDetail
