import { MdQuestionAnswer } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { faqServices } from '../../../../services/common/faqs'

// Components
import UpdateDeleteButton from '../../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

const FaqDetail = () => {
  const [faqData, setFaqData] = useState<any | null>(null)
  const id = location.pathname.split('/faq/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    faqServices.findOne(id).then((response) => {
      setFaqData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    faqServices.remove(id).finally(() => {
      navigate('/faq')
    })
  }

  if (!faqData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Faq' />
      <GeneralCard title='Faq Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Title' lowerText={faqData.title} />
          <DetailCard upperText='Summary' lowerText={faqData.summary} />
          <DetailCard upperText='Description' lowerText={faqData.description} />
          <DetailCard upperText='Order Number' lowerText={faqData.orderNumber} />
          <DetailCard upperText='Language' lowerText={faqData.languageCode} />
          <DetailCard upperText='Project' lowerText={faqData.projectId} />
        </Grid>
      </GeneralCard>
      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl={'/faq/update'} />
    </DefaultLayout>
  )
}

export default FaqDetail
