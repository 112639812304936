import { withLoggedIn, withLoggedOut } from './withLoggedIn'
import AccessDenied from '../pages/error/AccessDenied'
import { Route, Routes } from 'react-router-dom'
import _404 from '../pages/error/404'
import Dashboard from '../pages/dashboard/dashboard'

// User
import User from '../pages/users/users'
import UserDetail from '../pages/users/users/detail/index'
import UserCreate from '../pages/users/users/create/index'
import UserUpdate from '../pages/users/users/update/index'
import UserContractSign from '../pages/users/userContracts/contractSign/index'
import Congratulation from '../pages/users/userContracts/congratulation/index'

// Faq
import Faq from '../pages/common/faqs/index'
import FaqDetail from '../pages/common/faqs/detail/index'
import FaqCreate from '../pages/common/faqs/create/index'
import FaqUpdate from '../pages/common/faqs/update/index'

// Project
import Project from '../pages/common/projects/index'
import ProjectDetail from '../pages/common/projects/detail/index'
import ProjectCreate from '../pages/common/projects/create/index'
import ProjectUpdate from '../pages/common/projects/update/index'

// Language
import Language from '../pages/common/languages/index'
import LanguageDetail from '../pages/common/languages/detail/index'
import LanguageCreate from '../pages/common/languages/create/index'
import LanguageUpdate from '../pages/common/languages/update/index'

// Company
import Company from '../pages/common/companies/index'
import CompanyDetail from '../pages/common/companies/detail/index'
import CompanyCreate from '../pages/common/companies/create/index'
import CompanyUpdate from '../pages/common/companies/update/index'

// ContractLevel
import ContractLevel from '../pages/common/contractLevels/index'
import ContractLevelDetail from '../pages/common/contractLevels/detail/index'
import ContractLevelCreate from '../pages/common/contractLevels/create/index'
import ContractLevelUpdate from '../pages/common/contractLevels/update/index'

// VideoLink
import VideoLink from '../pages/common/videoLinks/index'
import VideoLinkDetail from '../pages/common/videoLinks/detail/index'
import VideoLinkCreate from '../pages/common/videoLinks/create/index'
import VideoLinkUpdate from '../pages/common/videoLinks/update/index'

// SocialMedia
import SocialMedia from '../pages/common/socialMedias/index'
import SocialMediaDetail from '../pages/common/socialMedias/detail/index'
import SocialMediaCreate from '../pages/common/socialMedias/create/index'
import SocialMediaUpdate from '../pages/common/socialMedias/update/index'

// SocialMedia
import ContentBank from '../pages/contentBanks/index'
// import ContentBankDetail from '../pages/common/socialMedias/detail/index'
import ContentBankCreate from '../pages/contentBanks/create/index'
// import ContentBankUpdate from '../pages/common/socialMedias/update/index'

// Employee
import Employee from '../pages/manageEmployees/index'
import EmployeeDetail from '../pages/manageEmployees/detail/index'
import EmployeeCreate from '../pages/manageEmployees/create/index'
import EmployeeUpdate from '../pages/manageEmployees/update/index'

import Login from '../pages/auth/login'

export const AuthRoute = (...args: Parameters<typeof Route>) => Route(...args)

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={withLoggedIn(Dashboard)()} />
      <Route path='/login' element={withLoggedOut(Login)()} />

      <Route path='/dashboard' element={withLoggedIn(Dashboard)()} />

      <Route path='/influencer' element={withLoggedIn(User)()} />
      <Route path='/influencer/:id' element={withLoggedIn(UserDetail)()} />
      <Route path='/influencer/update/:id' element={withLoggedIn(UserUpdate)()} />
      <Route path='/influencer/add' element={UserCreate()} />

      <Route path='/faq' element={withLoggedIn(Faq)()} />
      <Route path='/faq/:id' element={withLoggedIn(FaqDetail)()} />
      <Route path='/faq/add' element={withLoggedIn(FaqCreate)()} />
      <Route path='/faq/update/:id' element={withLoggedIn(FaqUpdate)()} />

      <Route path='/project' element={withLoggedIn(Project)()} />
      <Route path='/project/:id' element={withLoggedIn(ProjectDetail)()} />
      <Route path='/project/add' element={withLoggedIn(ProjectCreate)()} />
      <Route path='/project/update/:id' element={withLoggedIn(ProjectUpdate)()} />

      <Route path='/language' element={withLoggedIn(Language)()} />
      <Route path='/language/:id' element={withLoggedIn(LanguageDetail)()} />
      <Route path='/language/add' element={withLoggedIn(LanguageCreate)()} />
      <Route path='/language/update/:id' element={withLoggedIn(LanguageUpdate)()} />

      <Route path='/company' element={withLoggedIn(Company)()} />
      <Route path='/company/:id' element={withLoggedIn(CompanyDetail)()} />
      <Route path='/company/add' element={withLoggedIn(CompanyCreate)()} />
      <Route path='/company/update/:id' element={withLoggedIn(CompanyUpdate)()} />

      <Route path='/contract-level' element={withLoggedIn(ContractLevel)()} />
      <Route path='/contract-level/:id' element={withLoggedIn(ContractLevelDetail)()} />
      <Route path='/contract-level/add' element={withLoggedIn(ContractLevelCreate)()} />
      <Route path='/contract-level/update/:id' element={withLoggedIn(ContractLevelUpdate)()} />

      <Route path='/video-link' element={withLoggedIn(VideoLink)()} />
      <Route path='/video-link/:id' element={withLoggedIn(VideoLinkDetail)()} />
      <Route path='/video-link/add' element={withLoggedIn(VideoLinkCreate)()} />
      <Route path='/video-link/update/:id' element={withLoggedIn(VideoLinkUpdate)()} />

      <Route path='/social-media' element={withLoggedIn(SocialMedia)()} />
      <Route path='/social-media/:id' element={withLoggedIn(SocialMediaDetail)()} />
      <Route path='/social-media/add' element={withLoggedIn(SocialMediaCreate)()} />
      <Route path='/social-media/update/:id' element={withLoggedIn(SocialMediaUpdate)()} />

      <Route path='/gallery' element={withLoggedIn(ContentBank)()} />
      <Route path='/gallery/:id' element={withLoggedIn(CompanyDetail)()} />
      <Route path='/gallery/add' element={withLoggedIn(ContentBankCreate)()} />
      <Route path='/gallery/update/:id' element={withLoggedIn(CompanyUpdate)()} />

      <Route path='/manage-employee' element={withLoggedIn(Employee)()} />
      <Route path='/manage-employee/:id' element={withLoggedIn(EmployeeDetail)()} />
      <Route path='/manage-employee/add' element={withLoggedIn(EmployeeCreate)()} />
      <Route path='/manage-employee/update/:id' element={withLoggedIn(EmployeeUpdate)()} />

      <Route path='/contract-sign/:id' element={<UserContractSign />} />
      <Route path='/congratulation' element={<Congratulation />} />

      <Route path='/access-denied' element={withLoggedIn(AccessDenied)()} />
      <Route path='*' element={withLoggedIn(Dashboard)()} />
    </Routes>
  )
}
